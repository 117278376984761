var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import auth from './authService';
// A flag for retching a new access token. Request get stacked when this happens and are release if a new token is received.
var isAlreadyFetchingAccessToken = false;
// For Refreshing Token, keep a list of requests, so we can retry them
var subscribers = [];
var apiClient = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
// Otherwise axios inserts application/x-www-form-urlencoded by default; our CORS policy will block.
apiClient.defaults.headers.post['Content-Type'] = 'application/json';
// We set an interceptor for each request to
// include Bearer token to the request if user is logged in
apiClient.interceptors.request.use(function (config) {
    if (auth.accessToken) {
        config.headers = __assign(__assign({}, config.headers), { Authorization: "Bearer ".concat(auth.accessToken) });
    }
    return config;
});
// Add request/response interceptor
apiClient.interceptors.response.use(function (response) { return response; }, function (error) {
    // const { config, response: { status } } = error
    var config = error.config, response = error.response;
    var originalRequest = config;
    try {
        // If it's a 401 (potential token timeout) and we're not on an endpoint related to auth, then try refreshing
        if (response && response.status === 401
            && !originalRequest.url.startsWith('/auth/')
            && !originalRequest.url.startsWith('/two-factor/')) {
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                auth.refresh().then(function (res) {
                    isAlreadyFetchingAccessToken = false;
                    subscribers = subscribers.filter(function (callback) { return callback(auth.accessToken); });
                });
            }
            return new Promise(function (resolve) {
                subscribers.push(function (accessToken) {
                    originalRequest.headers.Authorization = "Bearer ".concat(accessToken);
                    resolve(apiClient(originalRequest));
                });
            });
        }
    }
    catch (err) {
        console.log('Try catch in response interceptor: ', err);
    }
    return Promise.reject(error);
});
export default apiClient;
